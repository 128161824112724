import useJwt from '@/auth/jwt/useJwt'

export async function geraBoleto(
  institutoId,
  tomadorId,
  {
    valor = 0,
    multa = 0,
    nossoNumero = '0',
    numeroDocumento = '0',
    dataEmissao = new Date().toISOString(),
    dataVencimento = undefined,
  },
) {
  const dadosBancarios = (await useJwt.get(`cadastro/instituto/bancarioInst/${institutoId}`)).data[0]
  const instituto = dadosBancarios.instituto
  const tomador = (await useJwt.get('cadastro/tomador/' + tomadorId)).data

  const beneficiario = {
    CPFCNPJ: instituto.cnpj,
    Nome: instituto.nome,
    Codigo: dadosBancarios.codigoConvenioBoleto,
    CodigoDV: dadosBancarios.codigoDVBoleto ?? '0',
    CodigoTransmissao: '',
    Endereco: {
      LogradouroEndereco: instituto.logradouro,
      LogradouroNumero: instituto.numero,
      LogradouroComplemento: instituto.complemento,
      Bairro: instituto.bairro,
      Cidade: instituto.cidade,
      UF: instituto.uf,
      CEP: instituto.cep,
    },
    ContaBancaria: {
      Agencia: dadosBancarios.dadoBancarioAgenciaBoleto,
      DigitoAgencia: dadosBancarios.dadoBancarioAgenciaDVBoleto,
      Conta: dadosBancarios.dadoBancarioContaBoleto,
      DigitoConta: dadosBancarios.dadoBancarioContaDVBoleto,
      CarteiraPadrao: 'SIG14',
      TipoCarteiraPadrao: 1,
      TipoFormaCadastramento: 1,
      TipoImpressaoBoleto: 2,
    },
  }

  const Pagador = {
    CPFCNPJ: tomador.cpf,
    Nome: tomador.nomeCompleto,
    Observacoes: '',
    Endereco: {
      LogradouroEndereco: tomador.logradouroEndereco,
      LogradouroNumero: tomador.numeroEndereco,
      Bairro: tomador.bairroEndereco,
      Cidade: tomador.cidadeEndereco,
      UF: tomador.ufEndereco,
      CEP: tomador.cepEndereco,
    },
  }

  return {
    valor,
    multa,
    nossoNumero,
    numeroDocumento,
    dataEmissao,
    dataVencimento,
    banco: Number(dadosBancarios.banco.codigo),
    Beneficiario: beneficiario,
    Pagador,
  }
}

export async function adicionaBoletoNaGeracaoDeRemessa({
  institutoId = undefined,
  parcelaFinanceiroId = undefined,
  tomadorId = undefined,
  grpeId = undefined,
  dataVencimento = undefined,
  renegociacaoParcelasId = undefined,
  renegociacaoAntecipacaoId = undefined,
  orgaoId = undefined,
  geradoPor = undefined,
}) {
  try {
    let dataToInsert = {
      institutoId,
      parcelaFinanceiroId,
      dataVencimento,
      tomadorId,
      grpeId,
      orgaoId,
      renegociacaoParcelasId,
      renegociacaoAntecipacaoId,
      [geradoPor]: true,
    }

    const response = await useJwt.post('boleto-prisma', {
      institutoId,
      parcelaFinanceiroId,
      dataVencimento,
      tomadorId,
      grpeId,
      orgaoId,
      renegociacaoParcelasId,
      renegociacaoAntecipacaoId,
      [geradoPor]: true,
    })
    return {
      status: 200,
      ...response,
    }
  } catch (e) {
    return {
      status: e.status,
      message: e.message,
    }
  }
}

export async function consultaBoletoGerado({
  institutoId = undefined,
  parcelaFinanceiroId = undefined,
  tomadorId = undefined,
  grpeId = undefined,
  dataVencimento = undefined,
  renegociacaoParcelasId = undefined,
  geradoPor = undefined,
}) {
  try {
    let dataToInsert = {
      institutoId,
      parcelaFinanceiroId,
      dataVencimento,
      tomadorId,
      grpeId,
      renegociacaoParcelasId,
      [geradoPor]: true,
    }

    const response = await useJwt.post('boleto-prisma/GetBoleto', {
      institutoId,
      parcelaFinanceiroId,
      dataVencimento,
      tomadorId,
      grpeId,
      renegociacaoParcelasId,
      [geradoPor]: true,
    })
    return {
      status: 200,
      ...response,
    }
  } catch (e) {
    return {
      status: 500,
      message: e.message,
    }
  }
}
