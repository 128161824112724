<template>
  <div>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="ToolIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Contrato Parcelas Vencidas</span>
        </template>
        <parcelasVencidasPendentes />
      </b-tab>
      <b-tab title="Contratos Regularizados">
        <template #title>
          <feather-icon icon="TrendingUpIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Contratos Parcelas Regularizadas</span>
        </template>
        <parcelasVencidasRegularizadas />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import parcelasVencidasPendentes from './components/parcelasVencidasPendentes.vue'
  import parcelasVencidasRegularizadas from './components/parcelasVencidasRegularizadas.vue'
  export default {
    components: {
      parcelasVencidasPendentes,
      parcelasVencidasRegularizadas,
    },
  }
</script>

ContratosRegularizados
