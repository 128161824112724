// Take as input a year and a number like 202402 and convert it to month name slash year
// Ex: 202402 => fev/2024
export const formatarCompetencia = (anoMes) => {
  const ano = String(anoMes).slice(0, 4)
  const mes = String(anoMes).slice(4, 6)
  const mesNome = obterMesNome(mes)
  return `${mesNome}/${ano}`
}

// Path: src/%40core/utils/obter-mes-nome.js
// Take as input a number like 2 and convert it to month name
// Ex: 2 => fev
export const obterMesNome = (mes) => {
  const mesNome = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]
  return mesNome[mes - 1]
}
