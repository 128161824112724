<template>
  <b-card-code no-body>
    <b-card-body>
      <b-row>
        <b-col md="2">
          <b-form-group label="Pesquisar Por" label-for="opcao">
            <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Status" label-for="status">
            <b-form-select id="status" :options="statusOpcoes" v-model="pesquisar.statusOpcoes" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Descrição" label-for="descricao">
            <b-form-input
              id="descricao"
              placeholder="Descrição"
              v-model="pesquisar.descricao"
              v-on:keydown.enter="carregarGrid"
            />
          </b-form-group>
        </b-col>
        <b-col md="auto">
          <b-button
            :disabled="isBusy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 21px; width: 160px"
            @click="carregarGrid()"
          >
            {{ !isBusy ? 'Pesquisar' : '' }}
            <b-spinner small v-if="isBusy" />
          </b-button>
        </b-col>
        <b-col md="auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 21px; width: 160px"
            @click="novoAcordo"
          >
            Novo Acordo
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <b-table
      v-show="items.length > 0 || isBusy"
      striped
      class="responsive"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
      :current-page="pesquisar.currentPage"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong> Carregando...</strong>
        </div>
      </template>

      <template #cell(status)="row">
        <b-badge :variant="statusMap[row.item.status][1]">
          {{ statusMap[row.item.status][0] }}
        </b-badge>
      </template>

      <template #cell(opcoes)="row">
        <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>
          <b-dropdown-item @click="visualizarRenegociacao(row.item)">
            <feather-icon icon="EyeIcon" size="16" />
            <span class="align-middle ml-50">Visualizar Renegociação</span>
          </b-dropdown-item>
          <b-dropdown-item @click="abrirModalVencimento(row.item)">
            <feather-icon icon="DollarSignIcon" size="16" />
            <span class="align-middle ml-50">Gerar Boleto</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>

    <!-- Modal Cadastro -->
    <b-modal
      id="modal-renegociacao"
      ref="modal-renegociacao"
      centered
      size="lg"
      title="Nova Renegociação"
      no-close-on-backdrop
      hide-footer
    >
      <validation-observer ref="formRenegociacao">
        <renegociacao-cadastro-modal
          id="modalRenegociacao"
          ref="renegociacaoRef"
          @closeSimulationModal="fecharRenegociacao"
          @atualizarGrid="carregarGrid"
          :parametro="parametroRenegociacao"
        />
      </validation-observer>
    </b-modal>

    <!-- Visualizar Parcela -->
    <b-modal
      id="modal-parcela"
      ref="modal-parcela"
      centered
      title="Demonstrativo das Parcelas"
      ok-title="Fechar"
      ok-only
      ok-variant="outline-secondary"
      no-close-on-backdrop
    >
      <renegociacao-parcela-modal :parametro="parametroParcela" />
    </b-modal>

    <b-modal
      id="modal-vencimento-boleto"
      ref="modal-vencimento-boleto"
      centered
      title="Data de vencimento"
      hide-footer
      no-close-on-backdrop
      ok-only
    >
      <validation-observer ref="boletoRules" tag="form">
        <validation-provider #default="{ errors }" name="Data de Vencimento" rules="required">
          <b-form-datepicker
            button-variant="primary"
            class="datePickerWithInput"
            v-model="dataVencimento"
            :min="new Date()"
            :hide-header="true"
            :initial-date="new Date()"
            label-help=""
            label-calendar="Calendário"
            label-close-button="Fechar"
            label-current-month="Mês atual"
            label-next-decade="Próxima década"
            label-next-month="Próximo mês"
            label-next-year="Próximo ano"
            label-prev-decade="Década anterior"
            label-prev-month="Mês anterior"
            label-prev-year="Ano anterior"
            label-select-month="Selecione o mês"
            label-select-year="Selecione o ano"
            label-selected="Selecionado"
            label-no-date-selected="Nenhuma data selecionada"
            label-reset-button="Resetar"
            label-today-button="Hoje"
            label-today="Hoje"
            locale="pt"
            :date-format-options="{ day: 'numeric', month: '2-digit', year: 'numeric' }"
            aria-controls="dataVencimento"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          <b-button variant="primary" @click="gerarBoleto(itemBoleto)" class="mt-1" block>Gerar</b-button>
        </validation-provider>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-visualizar"
      ref="modal-visualizar"
      centered
      size="xl"
      title="Detalhes dessa Renegociação"
      hide-footer
      no-close-on-backdrop
      ok-only
    >
      <renegociacao-visualizar-modal ref="simulacaoRef" :parametro="visualizarSelecionada"></renegociacao-visualizar-modal>
    </b-modal>
  </b-card-code>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import RenegociacaoCadastroModal from '@/views/pages/renegociacao/dividas/renegociacaoParcelas/renegociacaoParcelasCadastroModal.vue'
  import RenegociacaoVisualizarModal from '@/views/pages/renegociacao/dividas/renegociacaoParcelas/renegociacaoParcelasVisualizarModal.vue'
  import { formatarData, formatarValor } from '@/libs/utils'
  import { geraBoleto, adicionaBoletoNaGeracaoDeRemessa, consultaBoletoGerado } from '@/utils/boleto/index'
  import DatePicker from '@/views/components/DatePicker.vue'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  const { saveAs } = require('file-saver')

  export default {
    components: {
      DatePicker,
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      RenegociacaoCadastroModal,
      RenegociacaoVisualizarModal,
      AutenticaSenha,
      formatarData,
    },
    directives: {
      Ripple,
    },

    data() {
      return {
        itemBoleto: undefined,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        pesquisar: {
          tipoOpcoes: 1,
          statusOpcoes: '',
          descricao: '',
          perPage: 10,
          currentPage: 1,
        },
        statusMap: {
          Pendente: ['Pendente', 'warning'],
          Gerado: ['Gerado', 'primary'],
          Pago: ['Liquidado', 'success'],
          Cancelado: ['Cancelado', 'danger'],
        },
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'Matrícula' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'Pendente', text: 'Pendente' },
        ],
        isBusy: false,
        required,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'codigo', label: 'Código' },
          { key: 'nome', label: 'Nome', sortable: true },
          { key: 'contrato', label: 'Nº Contrato' },
          {
            key: 'valorRenegociacao',
            label: 'Valor de Renegociação',
            formatter: (item) => (item ? formatarValor(item) : formatarValor(0)),
          },
          {
            key: 'dataGerado',
            label: 'Data Gerado',
            formatter: (item) => (item ? formatarData(item) : 'Erro na data'),
            sortable: true,
          },
          { key: 'status', label: 'Status', sortable: true },
        ],
        parametroRenegociacao: {},
        dataVencimento: null,
        parametroParcela: {},
        visualizarSelecionada: { tomador: {} },
        items: [],
        dados: {
          RenegociacaoConsultar: null,
          RenegociacaoCriarNovo: null,
          RenegociacaoAlterarStatus: null,
          RenegociacaoVisualizar: null,
        },
        acesso: {
          RenegociacaoConsultar: null,
          RenegociacaoCriarNovo: null,
          RenegociacaoAlterarStatus: null,
          RenegociacaoVisualizar: null,
        },
        grupoPermissao: {},
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.RenegociacaoConsultar = true
        this.acesso.RenegociacaoCriarNovo = true
        this.acesso.RenegociacaoAlterarStatus = true
        this.acesso.RenegociacaoVisualizar = true
      } else {
        this.acesso.RenegociacaoConsultar = this.userData.GrupoAcesso.RenegociacaoConsultar
        this.acesso.RenegociacaoCriarNovo = this.userData.GrupoAcesso.RenegociacaoCriarNovo
        this.acesso.RenegociacaoAlterarStatus = this.userData.GrupoAcesso.RenegociacaoAlterarStatus
        this.acesso.RenegociacaoVisualizar = this.userData.GrupoAcesso.RenegociacaoVisualizar
      }
    },
    methods: {
      authEEdita(item) {
        this.items.item = item
        this.items.mode = 'authEEdita'
        this.$refs['modal-auth-senha'].show()
      },
      authEExclui(item) {
        this.items.item = item
        this.items.mode = 'authEExclui'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authEEdita') {
            this.editar(this.items.item)
          } else if (this.items.mode == 'authEExclui') {
            this.excluir(this.items.item)
          }
        }
      },
      carregarGrid() {
        this.isBusy = true
        this.items = []

        let institutoId = this.userData.institutoSelecionado
        let pesquisar = {
          ...this.pesquisar,
          institutoId,
          origem: 'parcelasVencidasPendentes',
        }

        useJwt
          .post(`renegociacao/GetPesquisar`, pesquisar)
          .then((response) => {
            if (response.data.length > 0) {
              this.isBusy = false
              this.items = response.data
              this.totalRows = this.items.length
            } else {
              this.isBusy = false
              this.$message.error('Nenhuma renegociação foi encontrada')
            }
          })
          .catch((error) => {
            console.error(error)
          })
      },
      novoAcordo() {
        this.$refs['modal-renegociacao'].show()
      },
      fecharRenegociacao() {
        this.$refs['modal-renegociacao'].hide()
      },
      visualizarRenegociacao(item) {
        this.visualizarSelecionada = {
          ...item,
          primeiraParcela: formatarData(item.primeiraParcela),
        }
        this.$refs['modal-visualizar'].show()
      },
      async abrirModalVencimento(item) {
        this.itemBoleto = item
        this.$refs['modal-vencimento-boleto'].show()
      },
      async gerarBoleto(item) {
        this.$refs.boletoRules.validate().then(async (success) => {
          if (success) {
            this.$refs['modal-vencimento-boleto'].hide()
            this.$message.info('Iniciando a geração do boleto.')

            let rt = await adicionaBoletoNaGeracaoDeRemessa({
              institutoId: this.userData.Instituto.id,
              renegociacaoParcelasId: item.id,
              tomadorId: item.tomadorId,
              geradoPor: 'porRenegociacao',
              dataVencimento: this.dataVencimento,
            })

            if (rt.status != 200) {
              rt = await consultaBoletoGerado({
                institutoId: this.userData.Instituto.id,
                renegociacaoParcelasId: item.id,
                tomadorId: item.tomadorId,
                geradoPor: 'porRenegociacao',
                dataVencimento: this.dataVencimento,
              })
            }

            const obj = await geraBoleto(this.userData.Instituto.id, item.tomadorId, {
              valor: Number(item.valorRenegociacao ?? 0),
              dataVencimento: this.dataVencimento,
              nossoNumero: `${rt.data.id}`,
              numeroDocumento: `${rt.data.id}`,
            })

            const resp = await useJwt.post('boleto/geraBoleto', obj)

            if (resp.data.status != 200) {
              this.$message.error('Erro ao gerar boleto')
              this.dataVencimento = null
              return
            }

            this.$message.success('Boleto gerado com sucesso')
            this.dataVencimento = null
            const data = resp.data.boletoGerado
            const buffer = new Uint8Array(data.Body.data) // Extrai o buffer dos dados
            const blob = new Blob([buffer], { type: data.ContentType })
            saveAs(blob, 'boleto.pdf')
            const url = window.URL.createObjectURL(blob)
            window.open(url, '_blank')
            setTimeout(() => window.URL.revokeObjectURL(url), 100)
          }
        })
      },
    },
  }
</script>
