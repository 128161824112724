<template>
  <b-form>
    <b-row>
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <div class="d-flex justify-content-start">
          <b-avatar :src="null" :text="avatarText(dados.nomeCompleto)" size="104px" rounded />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ dados.nome }}
              </h4>
              <span class="card-text">CPF: {{ dados.cpf }}</span
              ><br />
              <span class="card-text"
                >Renegociação: <span class="badge badge-light-primary badge-pill">{{ dados.codigoRenegociacao }}</span></span
              >
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="BriefcaseIcon" class="mr-75" />
              <span class="font-weight-bold">Órgão</span>
            </th>
            <td class="pb-50">
              {{ dados.nomeOrgao }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Categoria</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ dados.categoria }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Telefone</span>
            </th>
            <td>
              {{ dados.telefone }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="AtSignIcon" class="mr-75" />
              <span class="font-weight-bold">E-mail</span>
            </th>
            <td>
              {{ dados.email }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="3">
        <b-form-group label="Valor da Renegociação" label-for="valor">
          <b-form-input
            id="valor"
            placeholder="Valor da Renegociação"
            :value="'R$ ' + dados.valorRenegociacao"
            v-number="number"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Parcelas" label-for="parcelas">
          <b-form-input id="parcelas" placeholder="Parcelas" v-model="this.dados.dadosParcelas.length" :disabled="true" />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Data Renegociação" label-for="dataRenegociacao">
          <b-form-input
            id="dataRenegociacao"
            class="form-control"
            type="text"
            placeholder="DD/MM/AAAA"
            :value="formatarData(dados.dataRenegociacao)"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Juros da Renegociação" label-for="jurosRenegociacao">
          <b-form-input
            id="jurosRenegociacao"
            placeholder="Juros da Renegociação"
            :value="formatarValor(dados.jurosRenegociacao)"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-table
          striped
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="isBusy"
        >
          <template #cell(valor)="row">
            {{ formatarValor(row.item.valor) }}
          </template>
          <template #cell(vencimento)="row">
            {{ formatarData(row.item.vencimento) }}
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
          <b-pagination
            first-number
            last-number
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-card-body>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import { isNumber, formatarValor, formatarData, formatarCodigoSequencial } from '@/libs/utils'
  import { avatarText } from '@core/utils/filter'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      formatarData,
      formatarCodigoSequencial,
      formatarValor,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    setup() {
      return {
        avatarText,
      }
    },
    data() {
      return {
        isNumber,
        formatarValor,
        formatarData,
        formatarCodigoSequencial,
        formatarValor,
        dados: {},
        isBusy: false,

        number: {},
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        perPage: 6,
        currentPage: 1,
        fields: [
          { key: 'parcelaAtual', label: 'Nº Parcela' },
          {
            key: 'valorParcelaRenegociacao',
            label: 'Valor da Parcela',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          { key: 'vencimento', label: 'Vencimento', formatter: (value) => (value ? formatarData(value) : '') },
        ],

        totalRows: 0,
        items: [],
        taxaShow: {},
      }
    },
    async mounted() {
      await this.carregarParcelas()
    },
    methods: {
      async carregarParcelas() {
        this.isBusy = true
        let rotaApi = `renegociacao/carregarDadosParcelas/${this.parametro.id}`

        await useJwt
          .get(rotaApi)
          .then(async (response) => {
            this.dados = response.data[0]
            this.items = this.dados.dadosParcelas
            this.isBusy = false
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>
