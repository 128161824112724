<template>
  <div>
    <b-form-datepicker
      placeholder="Selecionar data"
      v-model="value"
      @input="emitSelected"
      :min="min"
      locale="pt-br"
    ></b-form-datepicker>
  </div>
</template>

<script>
  export default {
    data() {
      const minDate = new Date()

      return {
        value: '',
        min: minDate,
      }
    },
    methods: {
      emitSelected() {
        this.$emit('select', this.value)
      },
    },
  }
</script>
